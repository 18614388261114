import React, {useState, useEffect} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Grid, Segment, Header, Form, Button, Icon, Divider, Message, Popup } from 'semantic-ui-react';
import ApiService from '../ApiService';
import UnderlightLogo from '../UnderlightLogo';

const characterTypes = [{
  text: 'dreamer',
  value: 'dreamer'
}, {
  text: 'nightmare',
  value: 'datoke'
}];

const focusTypes = [{
  key: 1,
  value: 'willpower',
  text: 'willpower',
  content: <Header content="Willpower" subheader="Known as GateKeepers, disciples can manipulate the fabric of dreams, altering the walls of thought to protect themselves and others from harmful evocations, even prevent or allow access to areas of the Dream City. GateKeepers are the warriors of the Dream City."/>
}, {
  key: 2,
  value: 'insight',
  text: 'insight',
  content: <Header content="Insight" subheader="Known as DreamSeers, disciples see the true nature of the dreamstate, are able to slip invisibly into its embrace, and have an intuitive understanding of Talismans. DreamSeers are the scholars of the Dream City."/>
}, {
  key: 3,
  value: 'resilience',
  text: 'resilience',
  content: <Header content="Resilience" subheader="Known as SoulMasters, disciples can transmute essence, the substance all inhabitants of Underlight form to shield their souls, and use this power to help or harm others. SoulMasters are the protectors of the Dream City."/>
}, {
  key: 4,
  value: 'lucidity',
  text: 'lucidity',
  content: <Header content="Lucidity" subheader="Known as FateSenders, disciples learn to directly change the experiences of others, and are able to alter the movement, sight, and essence of others. FateSenders are the tricksters of the Dream City."/>
}];

const avatarTypes = [{
  text: 'male',
  value: 'male',
  icon: 'man'
}, {
  text: 'female',
  value: 'female',
  icon: 'woman'
}];

const CreateCharacterView = () => {
  const history = useHistory(),
        [message, setMessage] = useState(''),
        [nameReadOnly, setNameReadOnly] = useState(false),
        [characterForm, setCharacterForm] = useState({
          characterType: 'dreamer',
          characterFocus: 'willpower',
          characterName: '',
          characterAvatar: 'male'
        }),
        [characterNameError, setCharacterNameError] = useState(false);

  const handleCharacterNameChange = (event, {name, value}) => {
    setCharacterNameError(false);
    setCharacterForm((prevForm) => ({...prevForm, [name]: value}))
  }

  const handleDropdownChange = (event, {name, value}) => {
    setCharacterForm((prevForm) => ({...prevForm, [name]: value}))
  }

  const handleCharacterTypeChange = (event, {name, value}) => {
    setCharacterForm((prevForm) => ({...prevForm, characterType: value}))
    setTimeout(300);
    if (value === "datoke") {
      setNameReadOnly(true)
      setGeneratedCharacterName()
    } 
    else { 
      setNameReadOnly(false)
      setCharacterForm((prevForm) => ({...prevForm, characterName: ""}))
    }
  }

  const setGeneratedCharacterName = (type) => {
    ApiService.get('/character/namegen/' + characterForm.characterType)
    .then(apiCall => apiCall.data)
    .then(response => {
      setCharacterNameError(false);
      setCharacterForm((prevForm) => ({...prevForm, characterName: response.name}))
    })
    .catch(error => {
      console.log(error);
    });

  }

  const onCharacterGenerateNameIconClick = () => setGeneratedCharacterName()
  
  const onSubmit = () => {
    // Doing validation here since it is one field
    if (!characterForm.characterName) {
      setCharacterNameError(true);
      return;
    }

    ApiService.post('/player/character', {
      characterType: characterForm.characterType,
      focus: characterForm.characterFocus,
      characterName: characterForm.characterName,
      avatar: characterForm.characterAvatar
    })
    .then(apiCall => apiCall.data)
    .then(response => {
      history.push('/dashboard')
    })
    .catch(error => {
      setMessage(error.response.data.message)
    })
  }

  const [dreamersAvailableCount, setDreamersAvailableCount] = useState(0),
        [nightmaresAvailableCount, setNightmaresAvailableCount] = useState(0)

  const getCharacters = (data, e) => {
     ApiService.get('/player/character')
     .then(response => {
      setDreamersAvailableCount(response.data.maxDreamersAllowed - response.data.dreamerCount)
      setNightmaresAvailableCount(response.data.maxNightmaresAllowed - response.data.nightmareCount)
     })
     .catch(error => {
       console.log(error.response);
     });
  }

  useEffect(() => {
    getCharacters();
  }, []);

  return(
    <Grid textAlign='center' verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 500 }}>
      <UnderlightLogo/>
        <Segment inverted>
          <Message error hidden={!message}>{message}</Message>
          <Message info>
          <Message.Header>Characters Available</Message.Header>
          <Message.Item>Dreamers: &nbsp;&nbsp;&nbsp;&nbsp;{(dreamersAvailableCount <= 0) ? 0 : dreamersAvailableCount}</Message.Item>
          <Message.Item>Nightmares: {(nightmaresAvailableCount <= 0) ? 0 : nightmaresAvailableCount}</Message.Item>
        </Message>
            <Form className="inverted" onSubmit={onSubmit}>
              <Form.Dropdown placeholder='character type' name="characterType" defaultValue={characterForm.characterType} fluid selection options={characterTypes} onChange={handleCharacterTypeChange} />
              <Form.Field hidden={characterForm.characterType === "datoke"}>
                <Form.Dropdown placeholder='character type' name="characterFocus" defaultValue={characterForm.characterFocus} fluid selection options={focusTypes} onChange={handleDropdownChange} />
              </Form.Field>
              <Form.Input 
                icon={<Popup content="Click to generate a character name" trigger={<Icon name='refresh' link onClick={onCharacterGenerateNameIconClick} />} />} 
                value={characterForm.characterName} 
                placeholder="character name"
                name="characterName"
                maxLength="20"
                onChange={handleCharacterNameChange}
                error={characterNameError}
                readOnly={nameReadOnly}
              />
              <Form.Field hidden={characterForm.characterType === "datoke"}>
                <Form.Dropdown placeholder='avatar' name="characterAvatar" defaultValue={characterForm.characterAvatar} fluid selection options={avatarTypes} onChange={handleDropdownChange} />
              </Form.Field>
              <Form.Button 
                icon="add" 
                labelPosition="right" 
                size="large" 
                fluid 
                color="orange" 
                type="submit" 
                tabIndex="4"
                content="Create Character"
              />
              <Divider horizontal inverted/>
              <Link to='/dashboard'>
                  <Form.Button 
                    icon="arrow left"
                    labelPosition="left" 
                    size="large" fluid color="blue" 
                    tabIndex="4"
                    content="Dashboard" 
                  />
                </Link>
            </Form>
          </Segment>
        </Grid.Column>
      </Grid>
  )
}

export default CreateCharacterView;
