import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Grid, Message, Segment, Form } from 'semantic-ui-react';
import UnderlightLogo from '../UnderlightLogo';
import ApiService from '../ApiService';
import LocalStorageService from '../LocalStorageService';

const SetPasswordView = () => {
    const localStorageService = new LocalStorageService(),
	  { hash } = useParams(),
          history = useHistory(),
          [formHidden, setFormHidden] = useState(false),
          [infoMessage, setInfoMessage] = useState(false),
          [errorMessage, setErrorMessage] = useState(false),
          [header, setHeader] = useState(''),
          [message, setMessage] = useState(''),
          [passwordError, setPasswordError] = useState(false),
          [password, setPassword] = useState('')

    const passwordHashCheck = () => ApiService.post('/auth/player/login/recoveryhash', {
        hash: hash
    })
    .then((response) => {
        if (response.data.success) {
            localStorageService.setTokens(response.data.token,response.data.refreshToken);
            ApiService.enableInterceptors();

            setHeader('login successful');
            setMessage('please set your password');
            setFormHidden(false);
        }
    })
    .catch((error) => {
        setErrorMessage(true);
        setHeader('oops!');
        setMessage(<span>looks like this reset link has been used</span>)
        setFormHidden(true);
    })

    const handlePasswordChange = (event, {value}) => {
        setPassword(value);
    }

    const onSubmit = () => {
        ApiService.put('/player/password', {
            password: password
        })
        .then((response) => {
            if (response.data.success) {
                history.replace("/dashboard"); 
            }
        })
        .catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        passwordHashCheck();
    }, [])

    return(
        <Grid textAlign='center' verticalAlign='middle'>
          <Grid.Column style={{ maxWidth: 500 }}>
            <UnderlightLogo />
            <Message header={header} info={infoMessage} error={errorMessage} content={message} />
            <Form size='large' onSubmit={onSubmit} hidden={formHidden}>
              <Segment inverted stacked>
                <Form.Input
                  fluid
                  placeholder='password'
                  type='password'
                  autoFocus
                  autoCorrect="false"
                  tabIndex="1"
                  error={passwordError}
                  onChange={handlePasswordChange}
                />
                <Form.Button 
                size="large" 
                fluid 
                color="orange" 
                tabIndex='2'
                labelPosition='right' 
                icon='save' 
                type='submit'
                content='Save'/>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
        );
}

export default SetPasswordView;
