import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { Grid, Segment, Form, Message } from 'semantic-ui-react';
import UnderlightLogo from '../UnderlightLogo';
import ApiService from '../ApiService';

const ProfileView = () => {
  const [message, setMessage] = useState(''),
        [messagePositive, setMessagePositive] = useState(false),
        [username, setUsername] = useState(''),
        [fullName, setFullName] = useState(''),
        [emailAddress, setEmailAddress] = useState(''),
        [password, setPassword] = useState(''),
        [errorMessage, setErrorMessage] = useState('')

  const getUserSettings = () => {
    ApiService.get('/player')
    .then(apiCall => apiCall.data)
    .then(response => {
      if (response.success) {
        setUsername(response.username);
        setFullName(response.fullName);
        setEmailAddress(response.emailAddress);
      }      
    })
  }

  const handleFullNameChange = (event, {value}) => {
    setFullName(value);
  }

  const handleEmailAddressChange = (event, {value}) => {
    setEmailAddress(value)
  }

  const handlePasswordChange = (event, {value}) => {
    setPassword(value)
  }

  const onSubmit = () => {
    ApiService.put('/player', {
      fullName: fullName,
      emailAddress: emailAddress,
      password: password
    })
    .then(apiCall => apiCall.data)
    .then((response) => {
      if (response.success) {
        setMessagePositive(true);
        setMessage('changes saved');
        setErrorMessage('')
      }
    })
    .catch((error) => {
     setErrorMessage(error.response.data.errors[0])
     setMessage('')
    })
  }

  useEffect(() => {
    getUserSettings();
  }, [])

  return (<Grid textAlign='center' verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 500 }}>
          <UnderlightLogo/>
          <Segment inverted stacked>
            <Message error hidden={!errorMessage} content={errorMessage}/>
            <Message positive={messagePositive} hidden={!message} content={message} />
            <Form className="inverted" onSubmit={onSubmit}>
            <Form.Input 
              icon="game" 
              iconPosition="left" 
              placeholder="username" 
              name="username"
              defaultValue={username}
              readOnly />
            <Form.Input 
              icon="user" 
              iconPosition="left" 
              placeholder="full name" 
              name="fullName"
              defaultValue={fullName}
              tabIndex="1" 
              onChange={handleFullNameChange}
              />
            <Form.Input 
              icon="envelope" 
              iconPosition="left" 
              placeholder="email address" 
              name="emailAddress"
              defaultValue={emailAddress}
              tabIndex="2" 
              onChange={handleEmailAddressChange}
              />
            <Form.Input 
              icon="lock" 
              iconPosition="left" 
              placeholder="password" 
              name="password" 
              autoCorrect="false" 
              autoCapitalize="false" 
              spellCheck="false" 
              type="password" 
              tabIndex="3" 
              onChange={handlePasswordChange}
              />
            <Form.Button 
              size="large" 
              fluid 
              color="orange" 
              tabIndex='4'
              labelPosition='right' 
              icon='save' 
              content='Save Profile'/>
            <Link to='/dashboard'>
              <Form.Button 
              size="large" 
              fluid 
              color="blue" 
              tabIndex='5' 
              labelPosition='left'
              icon='arrow left'
              content='Dashboard' />
            </Link>
          </Form>
        </Segment>
    </Grid.Column>
  </Grid>
  )
};

export default ProfileView