import React from 'react'
import { Link } from 'react-router-dom'
import { Grid, Message } from 'semantic-ui-react'
import LocalStorageService from '../LocalStorageService'
import ApiService from '../ApiService'
import UnderlightLogo from '../UnderlightLogo'

const LogoutView = () => {
    const localStorageService = new LocalStorageService()
    ApiService.disableInterceptors()
    localStorageService.clearAll()

    setTimeout(() => {
      window.location.href = "/";
    }, 10000);

    return(
        <Grid textAlign='center' verticalAlign='middle'>
            <Grid.Column style={{ maxWidth: 500 }}>
                <UnderlightLogo/>
                <Message info>
                    <Message.Header>logged out</Message.Header>
                    <Message.Content><Link to='/' replace>click here</Link> to return to login.</Message.Content>
                </Message>
            </Grid.Column>
        </Grid>
    );
}

export default LogoutView
