import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UnderlightLogo from '../UnderlightLogo';
import { Grid, Segment, Form, Divider, Message, Popup } from 'semantic-ui-react';

const ImportCharacterView = () => {

  const characterTypes = [{
    text: 'dreamer',
    value: 'dreamer'
  }, {
    text: 'datoke',
    value: 'datoke'
  }];
  
  const focusTypes = [{
    text: 'willpower',
    value: 'willpower'
  }, {
    text: 'insight',
    value: 'insight'
  }, {
    text: 'resilience',
    value: 'resilience'
  }, {
    text: 'lucidity',
    value: 'lucidity'
  }];
  
  const avatarTypes = [{
    text: 'male',
    value: 'male',
    icon: 'man'
  }, {
    text: 'female',
    value: 'female',
    icon: 'woman'
  }];
  
  const underlightVersions = [{
    text: 'lyra',
    value: 'lyra'
  }, {
    text: 'shades of truth',
    value: 'sot'
  }];

  const [ formState, setFormState ] = useState({
    fullName: '',
    emailAddress: '',
    lastBillingAddress: '',
    characterName: '',
    characterType: 'dreamer',
    characterGender: 'male',
    characterFocus: 'willpower',
    characterSphere: 0,
    characterOrbit: 0,
    underlightVersion: 'lyra',
    additionalDetails: ''
  })

  return(
    <Grid textAlign='center' verticalAlign='middle'>
      <Grid.Column style={{ maxWidth: 500 }}>
        <Segment inverted>
          <UnderlightLogo/>
          <Message positive hidden/>
          <Form className="inverted">
            <Form.Input 
              icon='user' 
              iconPosition='left' 
              placeholder="full name (required)" 
              name="fullName" 
              tabIndex="1"
              autoFocus
              defaultValue={formState.characterName}
            />
            <Form.Input 
              icon="envelope" 
              iconPosition="left" 
              placeholder="email address" 
              name="emailAddress" 
              tabIndex="2"
              type="email"
              defaultValue={formState.emailAddress}
            />
            <Form.TextArea 
              placeholder='last billing address' 
              name="billingAddress" 
              tabIndex="3"
              defaultValue={formState.lastBillingAddress}
            />
            <Form.Input 
              icon='game' 
              iconPosition='left' 
              placeholder="character name (required)" 
              name="characterName" 
              tabIndex="4"
              defaultValue={formState.characterName}
            />
            <Form.Dropdown 
              placeholder='character type (required)' 
              name="characterType" 
              fluid tabIndex="2" 
              selection 
              options={characterTypes} 
              defaultValue={formState.characterType}
            />
            <Form.Dropdown 
              placeholder='avatar type' 
              name="avatar" 
              fluid tabIndex="3" 
              selection 
              options={avatarTypes} 
              defaultValue={formState.characterGender}
            />
            <Form.Dropdown 
              placeholder='character focus' 
              name="focus" 
              fluid 
              tabIndex="4" 
              selection 
              options={focusTypes} 
              defaultValue={formState.characterFocus}
            />
            <Form.Input 
              icon='globe' 
              iconPosition='left' 
              placeholder="sphere (0-9)" 
              name="sphere" 
              type="number" 
              min="0"
              max="9"
              tabIndex="6"
            />
            <Form.Dropdown
              placeholder='underlight version (required)' 
              name="underlightVersion" 
              fluid 
              tabIndex="7"
              selection 
              options={underlightVersions}
              defaultValue={formState.underlightVersion}
            />
            <Popup content="previous character description, email addresses, billing address, &amp; any other relevant information" trigger={
              <Form.TextArea 
                placeholder='additional details' 
                name="additionalDetails" 
                tabIndex="10"
                defaultValue={formState.additionalDetails}
              />
            }/>
            <Form.Button 
              size="large" 
              fluid 
              color="orange" 
              tabIndex="11"
              content='Import Character'
              type="submit"
            />
            <Divider horizontal inverted/>
            <Link to='/dashboard'>
              <Form.Button 
                size="large" 
                fluid 
                color="blue" 
                tabIndex="12"
                content='Go back'
                />
            </Link>
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}

export default ImportCharacterView;