class LocalStorageService {
  constructor() {
    this.ACCESS_TOKEN_KEY = process.env.REACT_APP_ACCESS_TOKEN_KEY
    this.REFRESH_TOKEN_KEY = process.env.REACT_APP_REFRESH_TOKEN_KEY
  }

  setAccessToken = (token) => {
    localStorage.setItem(this.ACCESS_TOKEN_KEY, token);
  }

  getAccessToken = () => {
    return localStorage.getItem(this.ACCESS_TOKEN_KEY)
  }

  setRefreshToken = (token) => {
    localStorage.setItem(this.REFRESH_TOKEN_KEY, token);
  }

  getRefeshToken = () => {
    return localStorage.getItem(this.REFRESH_TOKEN_KEY)
  }

  setTokens = (accessToken, refreshToken) => {
    this.setAccessToken(accessToken)
    this.setRefreshToken(refreshToken)
  }

  getTokens = () => {
    return({
      accessToken: this.getAccessToken(),
      refreshToken: this.getRefeshToken()
    })
  }

  clearAll = () => {
    localStorage.clear()
  }

}

export default LocalStorageService