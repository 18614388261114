import React, {useEffect, useState} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Grid, Header, Segment, Button, Icon, Menu, Popup, Card } from 'semantic-ui-react';
import UnderlightLogo from '../UnderlightLogo';
import CopyToClipboard from 'react-copy-to-clipboard';
import S from 'string';
import ApiService from '../ApiService';
import LocalStorageService from '../LocalStorageService';

const CharacterCard = (props) => {
  const [valueToCopy, setValueToCopy] = useState(''),
        [copied, setCopied] = useState(false),
        [buttonDisabled, setButtonDisabled] = useState(false);

  const onRetrievePasswordButtonClick = (e, button) => {
      ApiService.put('/player/character/' + button['data-id'] + '/password', {})
      .then(apiCall => apiCall.data)
      .then(response => {
        setValueToCopy(response.secret);
        setButtonDisabled(true);
        setCopied(false);
        console.log(response.secret);
      })
      .catch(error => {
        console.log(error.response);
      });
  }

  let description  = 'Sphere: ' + props.sphere;
      description += ' - Orbit: ' + props.orbit;
      description += ' - XP: ' + props.xp;

  return (<Card fluid={true}>
              <Card.Content>
                <Card.Header>{props.name}</Card.Header>
                <Card.Meta>
                  {S(props.type).capitalize().s} {props.type === "dreamer" ? '(' + S(props.focus).capitalize().s + ')' : ''}
                </Card.Meta>
                <Card.Description>{(props.type === "dreamer") ? description : ''}</Card.Description>
              </Card.Content>
              <Segment hidden={!valueToCopy}>
                
                <Popup content='Click to copy to clipboard' trigger={
                  <CopyToClipboard text={valueToCopy} onCopy={() => setCopied(true)}>
                    <Button secondary icon labelPosition="right">
                      <Icon name="clipboard outline" />
                        {valueToCopy}
                    </Button>
                  </CopyToClipboard>} />
                  
                <span hidden={!copied}>Copied to clipboard</span>
              </Segment>
              <Button disabled={buttonDisabled} attached="bottom" color="blue" data-id={props.characterId} data-name={props.name} onClick={onRetrievePasswordButtonClick}>Retrieve New Password</Button>
          </Card>)
}

const Characters = (props) => {
  const characterCards = props.data.map((character) => <CharacterCard key={character.characterId} {...character} />);
  return characterCards;
}

const DashboardView = () => {
 
  const [characters, setCharacters] = useState([]),
        [characterCount, setCharacterCount] = useState(0),
        [maxCharactersAllowed, setMaxCharactersAllowed] = useState(0),
        history = useHistory()

  const getCharacters = (data, e) => {
     ApiService.get('/player/character')
     .then(response => {
       setCharacters(response.data.characters)
       setCharacterCount(response.data.total)
       setMaxCharactersAllowed(response.data.maxCharactersAllowed)
     })
     .catch(error => {
       console.log(error.response);
     });
  }

  const onLogoutClick = () => {
    history.replace('/logout')
  }

  useEffect(() => {
    getCharacters();
  }, []);

return(
  <Grid textAlign='center' verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 500 }}>
      <Segment inverted>
        <UnderlightLogo/>
        <Menu text inverted>
          <Link to='/profile'>
            <Menu.Item>
            <Icon name="user circle" size="large"/>
            Profile
            </Menu.Item>
          </Link>
          <Menu.Menu position="right">
            <Menu.Item color="blue" onClick={onLogoutClick}>
            <Icon name="sign out" size="large"/>
            Logout
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Header as="h3" textAlign="center">Hey Dreamer!</Header>
        <Characters data={characters} />
        <Header hidden={characters.length} as="h4" textAlign="center">Looks like it is time to create your first character.</Header>
        {characterCount < maxCharactersAllowed ?
          <Popup 
            trigger={
                <Link to='/create-character'>
                  <Button icon="add" color="orange" size="large" fluid content="Create Character" labelPosition="right"/>
                </Link>
            }
            content='click here to a create a dreamer or nightmare character'
            inverted
            position="top center"
            />
          :
          <Container>Players are only allowed five characters. Please contact accounts@underlight.com for more information.</Container>
        }
      </Segment>
    </Grid.Column>
  </Grid>
  )
}

export default DashboardView;
