import React, { useEffect } from 'react';
import { BrowserRouter, Switch, Route} from 'react-router-dom';
import { loadReCaptcha  } from 'react-recaptcha-v3';

import LoginView from './login/LoginView';
import LogoutView from './logout/LogoutView';
import SignupView from './signup/SignupView';
import RetrieveUsernameView from './login/RetrieveUsernameView';
import ResetPasswordView from './login/ResetPasswordView';
import SetPasswordView from './login/SetPasswordView';
import ProfileView from './profile/ProfileView';
import DashboardView from './dashboard/DashboardView';
import CreateCharacterView from './character/CreateCharacterView';
import ImportCharacterView from './character/ImportCharacterView';
import ProtectedRoute from './ProtectedRoute';
import 'semantic-ui-less/semantic.less';
import './App.css';

function App() {

  useEffect(() => {
    loadReCaptcha(process.env.REACT_APP_RECAPTCHA_SITE_KEY)
  }) 
   
  return (
    <div className="App App-header">
      <BrowserRouter>
        <Switch>
          <Route path="/login" component={LoginView} />
          <Route path="/retrieve-username" component={RetrieveUsernameView} />
          <Route path="/reset-password" component={ResetPasswordView} />
          <Route path="/signup" component={SignupView} />
          <Route path='/password/:hash' component={SetPasswordView} />
          <Route path='/logout' component={LogoutView} />
          <ProtectedRoute exact={true} path="/" component={DashboardView} />
          <ProtectedRoute path="/profile" component={ProfileView} />
          <ProtectedRoute path="/create-character" component={CreateCharacterView} />
          <ProtectedRoute path='/import-character' component={ImportCharacterView} />
          <ProtectedRoute path="/" component={DashboardView} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
