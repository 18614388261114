import React from 'react'
import { Redirect } from 'react-router-dom'
import ApiService from './ApiService'
import LocalStorageService from './LocalStorageService'

const ProtectedRoute = (props) => {

    const Component = props.component,
          localStorageService = new LocalStorageService(),
          isAuthenticated = localStorageService.getRefeshToken()

    if (isAuthenticated) {
        ApiService.enableInterceptors()
        return <Component />
    }
    else
        return <Redirect to={{ pathname: '/login' }} />

}

export default ProtectedRoute;