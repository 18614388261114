import axios from 'axios'
import LocalStorageService from './LocalStorageService'
import S from 'string'

const apiUrl = S("{{url}}/{{version}}/").template({
	url: process.env.REACT_APP_API_URL, 
	version: process.env.REACT_APP_API_VERSION
}).s

const localStorageService = new LocalStorageService();
var requestInterceptor = 0,
    responseIntercceptor = 0;


const ApiService = axios.create({
	baseURL: apiUrl
})

ApiService.enableInterceptors = () => {
	// add request interceptor
	requestInterceptor = ApiService.interceptors.request.use(
		config => {
			const accessToken = localStorageService.getAccessToken()
			if (accessToken) {
				config.headers['Authorization'] = 'Bearer ' + accessToken
			}
			return config;
		},
		error => {
			Promise.reject(error)
		}
	)

	// add response interceptor
	responseIntercceptor = ApiService.interceptors.response.use((response) => {
		return response
	}, error => {
		const originalRequest = error.config

		// if (error.response.status === 401 
		// 	&& originalRequest.url === (apiUrl + '/auth/player/token')) {
		// 		console.log('redirect to login')
		// 		return Promise.reject(error)
		// 	}
		
		if (error.response.status === 401 && !originalRequest._retry) {
			originalRequest._retry = true
			const refreshToken = localStorageService.getRefeshToken()
			return ApiService.post('/auth/player/token', {
				"refreshToken": refreshToken
			})
			.then(response => {
				if (response.status === 201) {
					localStorageService.setAccessToken(response.data.token)
					ApiService.defaults.headers.common['Authorization'] = 'Bearer ' + localStorageService.getAccessToken()
					return ApiService(originalRequest)
				}
			})
		}
		return Promise.reject(error)
	})

}

ApiService.disableInterceptors = () => {
	ApiService.interceptors.request.eject(requestInterceptor)
	ApiService.interceptors.response.eject(responseIntercceptor)
}

export default ApiService