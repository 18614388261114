import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form, Grid, Message, Segment, Popup } from 'semantic-ui-react';
import { ReCaptcha } from 'react-recaptcha-v3';
import ApiService from '../ApiService';
import LocalStorageService from '../LocalStorageService';
import UnderlightLogo from '../UnderlightLogo';

const SignupView = () => {
  const history = useHistory(),
        localStorageService = new LocalStorageService(),
        [message, setMessage] = useState(''),
        [messageList, setMessageList] = useState([]),
        [formSubmitting, setFormSubmitting] = useState(false),
        [registerForm, setRegisterForm] = useState({
          username: '',
          fullName: '',
          emailAddress: '',
          password: '',
          agreeToTerms: false
        }),
        [usernameError, setUsrnameError] = useState(false),
        [fullNameError, setFullNameError] = useState(false),
        [emailAddressError, setEmailAddressError] = useState(false),
        [passwordError, setPasswordError] = useState(false),
        [agreeToTermsError, setAgreeToTermsError] = useState(false);

  const handleChange = (event, {name, value}) => setRegisterForm((prevForm) => ({...prevForm, [name]: value})) 
  const handleCheckboxChange = (event, {name, checked}) => setRegisterForm((prevForm) => ({...prevForm, [name]: checked})) 

  // Client-side validation for empty or false values
  const handleRegisterFormValidation = () => {
    let errorFound = false;

    Object.entries(registerForm).forEach(([name, value]) => {
      if (!value) {
        errorFound = true;

        if (name === 'username') setUsrnameError(true)

        if (name === 'fullName') setFullNameError(true)

        if (name === 'emailAddress') setEmailAddressError(true)

        if (name === 'password') setPasswordError(true)

        if (name === 'agreeToTerms') setAgreeToTermsError({
          content: 'You must agree to the KoiWare Terms of Service before continuing',
          pointing: 'below'
        })
      }
    })

    return errorFound;
  }

  const onSubmit = () => {
    const errors = handleRegisterFormValidation();
    if (errors) return;

    setFormSubmitting(true);

    ApiService.post('/auth/player/register', registerForm)
    .then(response => {
      setFormSubmitting(false);

        // Set JWE access and refresh tokens
        localStorageService.setTokens(response.data.token, response.data.refreshToken)

        // Send user to Dashboard
        history.replace("/dashboard");   
    })
    .catch(error => {
      setFormSubmitting(false);
      if (error.response.status === 400) {
        setMessage(true);
        setMessageList(error.response.data.errors);
      }
      else {
        setMessage(error.response.data.message)
      }
      console.log(error.response)
      // setMessage(error.response.data.errors.join('<br/>'));
    });
  }

  return (
  <Grid textAlign='center' verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 500 }}>
      <UnderlightLogo/>
      <Message error hidden={!message} content={message} list={messageList}/>
        <Segment inverted stacked>
        <Form size='large' onSubmit={onSubmit} loading={formSubmitting}>
              <Popup content="Must be 5 to 20 characters without special characters" trigger={
              <Form.Input 
                icon="game" 
                iconPosition="left" 
                placeholder="username" 
                name="username" 
                autoFocus 
                autoCorrect="false" 
                autoCapitalize="false" 
                spellCheck="false" 
                tabIndex="1"
                onChange={handleChange}
                error={usernameError}
              />
              } />
              <Popup content="Must include first and last name" trigger={
              <Form.Input
                icon="user" 
                iconPosition="left" 
                placeholder="full name" 
                name="fullName" 
                autoCorrect="false" 
                autoCapitalize="false" 
                spellCheck="false" 
                tabIndex="2"
                onChange={handleChange}
                error={fullNameError}
                />
              } />
              <Form.Input 
                icon="envelope" 
                iconPosition="left" 
                placeholder="email address" 
                name="emailAddress" 
                tabIndex="3"
                type="email"
                onChange={handleChange}
                error={emailAddressError}
                />
              <Popup content="Must at least 6 characters and may include special characters" trigger={
              <Form.Input 
                icon="lock" 
                iconPosition="left" 
                placeholder="password" 
                name="password" 
                autoCorrect="false" 
                autoCapitalize="false" 
                spellCheck="false" 
                type="password" 
                tabIndex="4"
                onChange={handleChange}
                error={passwordError}
                />
              } />
            <Form.Checkbox 
                label={<label style={{color: "#fff"}}>By creating an account, I agree to the <a target="_blank" rel="noopener noreferrer" href="https://underlight.com/terms-of-service/">KoiWare Terms of Service</a></label>}
                name="agreeToTerms" 
                tabIndex="5"
                onChange={handleCheckboxChange}
                error={agreeToTermsError}
            />
            <Form.Button 
              size="large" 
              fluid 
              color="orange" 
              tabIndex="6" 
              labelPosition='right' 
              icon='sign-in' 
              type="submit"
              content='Submit'
              />
            <Link to='/'>
              <Form.Button 
              size="large" 
              fluid 
              color="blue" 
              tabIndex="7" 
              labelPosition='left'
              icon='arrow left'
              content='Go back' />
            </Link>
          </Form>
        </Segment>
    </Grid.Column>
  </Grid>)
};

export default SignupView
