import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { Form, Segment, Grid, Message } from 'semantic-ui-react';
import ApiService from '../ApiService';
import UnderlightLogo from '../UnderlightLogo';

const RetrieveUsernameView = () => {
  const [message, setMessage] = useState(''),
        [emailAddress, setEmailAddress] = useState(''),
        [emailAddressError, setEmailAddressError] = useState(false),
        [emailAddressErrorMessage, setEmailAddressErrorMessage] = useState(false),
        [emailAddressSuccessMessage, setEmailAddressSuccessMessage] = useState(false),
        [retrieveButtonDisabled, setRetrieveButtonDisabled] = useState(false);

  const onSubmit = () => {
    // Doing validation here since it is one field
    if (!emailAddress) setEmailAddressError(true);

    setRetrieveButtonDisabled(true);

    ApiService.post('/auth/player/username', {
      emailAddress: emailAddress
    })
    .then(apiCall => apiCall.data)
    .then((response) => {
      if (response.success) {
        setEmailAddressSuccessMessage(true);
        setMessage('an email should arrive shortly with your username');
      }
    })
    .catch((event) => {
      setMessage(event.response.data.message);
      setEmailAddressErrorMessage(true);
      setRetrieveButtonDisabled(false);
    })
  }

  const handleEmailAddressChange = (event, {name, value}) => {
    setEmailAddress(value);
  }

return(
  <Grid textAlign='center' verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 500 }}>
      <UnderlightLogo/>
      <Message success={emailAddressSuccessMessage} error={emailAddressErrorMessage} content={message} hidden={!message}/>
      <Form size='large' onSubmit={onSubmit}>
        <Segment inverted stacked>
          <Form.Input
            fluid
            icon='envelope'
            iconPosition='left'
            placeholder='email address'
            type='email'
            autoFocus
            autoCorrect="false"
            tabIndex="1"
            error={emailAddressError}
            onChange={handleEmailAddressChange}
          />
          <Form.Button 
            color='orange' 
            fluid 
            size='large' 
            labelPosition='right'
            icon='refresh'
            content='Retrieve Username'
            tabIndex='2'
            disabled={retrieveButtonDisabled}
          />
          <Link to='/'>
            <Form.Button 
            size="large" 
            fluid 
            color="blue"
            labelPosition='left'
            icon='arrow left'
            content='Go back'
            tabIndex='3'
            />
          </Link>
        </Segment>
      </Form>
    </Grid.Column>
  </Grid>
  );
}

export default RetrieveUsernameView