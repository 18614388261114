import React from 'react';
import { Image } from 'semantic-ui-react';

const UnderlightLogo = () => (
    <Image 
      href="https://underlight.com" 
      src="/resources/images/underlight-harp-logo.png" 
      centered style={{height: 160, width: 343}} 
    />
);

export default UnderlightLogo;