import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Form, Divider, Grid, Container, Segment, Message } from 'semantic-ui-react';
import { ReCaptcha } from 'react-recaptcha-v3';
import ApiService from '../ApiService';
import LocalStorageService from '../LocalStorageService';
import UnderlightLogo from '../UnderlightLogo';

const LoginView = () => {

  const history = useHistory(),
        localStorageService = new LocalStorageService(),
        [message, setMessage] = useState(''),
        [username, setUsername] = useState((localStorage.getItem('username') ? localStorage.getItem('username') : '')),
        [password, setPassword] = useState(''),
        [rememberMe, setRememberMe] = useState((localStorage.getItem('rememberMe') ? true : false))

  const saveRememberMe = () => {
    if (rememberMe) {
        localStorage.setItem('username', username);
        localStorage.setItem('rememberMe', true);
    }
    else {
        localStorage.removeItem('username');
        localStorage.removeItem('rememberMe');      
    }
  };

  const toggleRememberMe = () => {
    (rememberMe) ? setRememberMe(false) : setRememberMe(true);
  };

  const onSubmit = () => {
    window.grecaptcha.ready(() => {
    window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'login' }).then(recaptchaToken => {
        ApiService.post('auth/player/login', {
          username: username,
          password: password,
          rememberMe: rememberMe,
          recaptchaToken: recaptchaToken
        })
        .then(response => {
          if (response.data.success) {
            // If rememberMe is checked, then
            // save username value and rememberMe flag in localStorage
            saveRememberMe(true);
    
            // Set JWE access and refresh tokens
            localStorageService.setTokens(response.data.token, response.data.refreshToken)
    
            history.replace("/dashboard");        
          }
        })
        .catch((error) => {
          setMessage(error.response.data.message);
        });
     })
   })

  };

return (
    <Grid textAlign='center' verticalAlign='middle'>
    <Grid.Column style={{ maxWidth: 500 }}>
      <UnderlightLogo/>
      <Message error hidden={!message} content={message} />
        <Segment inverted stacked>
        <Form size='large' onSubmit={onSubmit} autoComplete="false">
          <Form.Input 
            fluid 
            icon='user'  
            iconPosition='left' 
            placeholder='username'
            name='username'
            autoFocus 
            autoCorrect="false" 
            tabIndex="1"
            defaultValue={username}
            onChange={e => setUsername(e.target.value)}
            />
          <Container textAlign="left" style={{paddingBottom: '10px', marginBottom: '5px', lineHeight: 0}}>
            <Link to='/retrieve-username'>retrieve username</Link>
          </Container>
          <Form.Input
            fluid
            icon='lock'
            iconPosition='left'
            placeholder='password'
            name='password'
            type='password'
            autoCorrect="false"
            tabIndex="2"
            defaultValue={password}
            onChange={e => setPassword(e.target.value)}
          />
          <Container textAlign="left" style={{paddingBottom: '10px', marginBottom: '5px', lineHeight: 0}}>
            <Link to='/reset-password'>reset password</Link>
          </Container>
          <Container textAlign="left" style={{marginBottom: '10px'}}>
            <Form.Checkbox 
              label={<label style={{color: '#fff'}}>remember me for 30 days</label>} 
              name='rememberMe'
              tabIndex="3"
              defaultChecked={rememberMe}
              onChange={toggleRememberMe}
            />
          </Container>
          <Button 
            color='blue' 
            fluid 
            size='large' 
            labelPosition='right'
            icon='sign-in'
            content='Sign in'
            type='submit'
          />
          <Divider horizontal inverted>New to Underlight?</Divider>
          <Link to='/signup'>
              <Button 
                color='orange' 
                fluid 
                size='large'
                labelPosition='right'
                icon='add user'
                content='Create an account'
              />
          </Link>
        </Form>
        </Segment>
    </Grid.Column>
  </Grid>
  )
}

export default LoginView;
